import { currentEnv, domain } from "env"
import { useRouter } from "next/router"
import Link from "next/link"
import { useEffect, useState } from "react"
import { useTranslations } from "use-intl"
import { Alert } from "@trussworks/react-uswds"
import dynamic from "next/dynamic"
import useInView from "react-cool-inview"
import { JobDetailSection, LabelActive } from "components/JobSearch"
import { JobDetailSkeleton } from "components/Skeletons"
import {
  cleanListing,
  cleanListingForStructuredData,
  prefixLocale,
} from "utils"
import { postPopularity } from "utils/fetchers/postPopularity"
import { PAGE_VIEW } from "const/popularityEvents"
import {
  renderAdditionalJobOrderDetails,
  renderAwaitingTranslation,
  renderBackButton,
  renderJobOrder,
  renderStructuredData,
} from "components/JobSearch/JobDetails"
import {
  EmployerInformationSection,
  JobDescriptionSection,
  JobOrderDetailsSection,
  JobRequirementsSection,
  RecruitmentSection,
  ShareSection,
} from "components/JobSearch/JobDetailSections"
import { event } from "utils/googleAnalytics"

export function JobDetail({ listing, card, classNameOverride, atlasRef }) {
  const router = useRouter()
  const t = useTranslations("Shared")
  const t2 = useTranslations("JobListing")
  const [copyLink, setCopyLink] = useState()
  const locale = router.locale

  const { observe, inView } = useInView({
    onEnter: ({ unobserve }) => unobserve(), // only run once
  })

  useEffect(() => {
    async function recordPopularity() {
      try {
        await postPopularity({
          case_number: listing.case_number,
          event_type: PAGE_VIEW,
        })
      } catch (e) {
        console.log(e)
      }
    }
    if (!card) {
      recordPopularity()
    }

    const top = document.getElementById("job-detail")
    if (top) {
      top.scrollTop = 0
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listing])

  useEffect(() => {
    if (listing) {
      setCopyLink(
        `${location.protocol + "//" + location.host}/${prefixLocale(
          locale
        )}jobs/${listing.case_number}`
      )
    }
  }, [listing, locale])

  const structuredData = cleanListingForStructuredData({ listing, locale })

  const cleanedListing = cleanListing({
    listing,
    locale,
    t,
  })

  const {
    active,
    case_number,
    jobOrderExists,
    translated,
    // PREVIEW
    preview,
    employer,
    // RECRUITMENT INFO
    recruitment,
    // JOB DESCRIPTION
    jobDescription,
    // JOB REQUIREMENTS
    jobRequirements,
    // EMPLOYER INFORMATION
    employerInformation,
    // WORKSITE
    worksite,
    // JOB ORDER DETAILS
    jobOrderDetails,
  } = cleanedListing || {}

  const JobDetailMap = dynamic(() =>
    import("components/JobSearch").then((mod) => mod.JobDetailMap)
  )

  if (!listing) {
    return (
      <JobDetailSkeleton card={card} classNameOverride={classNameOverride} />
    )
  }

  return (
    <>
      {renderBackButton({ card, router, t2 })}
      <div
        id="job-detail"
        className={
          card
            ? `flex-shrink-0  transition-all md:h-38rem top-0 bg-white md:relative md:flex md:flex-1` +
              ` border-2 border-gray-300 rounded-md space-2 justify-between overflow-y-scroll px-6 py-8 ${classNameOverride}`
            : `flex-shrink-0  transition-all grid-container pt-10 ${classNameOverride}`
        }
      >
        {renderStructuredData({ card, case_number, structuredData })}
        <section className="flex flex-col flex-shrink-0 flex-1">
          <Link href={`/jobs/${case_number}`} passHref>
            <a
              onClick={() => {
                event({
                  action: `${currentEnv}-job_order_download`,
                  category: case_number,
                  label: "click",
                  value: 1,
                })
              }}
            >
              <h2
                className={
                  `text-primary-dark hover:text-green-700 text-2xl font-bold` +
                  ` font-serif border-b-4 rounded-6 border-primary-dark hover:border-green-700 pb-2 cursor-pointer`
                }
              >
                {preview.title}
              </h2>
            </a>
          </Link>

          <div className="flex flex-row flex-shrink-0  my-4 w-full text-lg">
            <div className="flex flex-col flex-shrink-0 flex-1 justify-between">
              <div className="mb-8">
                <p className="text-gray-500">{employer.name}</p>
                <p className="text-gray-500">{worksite.location}</p>
              </div>

              <p>{preview.pay}</p>
            </div>
            <div className="flex flex-col flex-shrink-0 flex-1 text-right justify-between">
              <div className="flex flex-col flex-shrink-0">
                <time dateTime={preview.beginDate}>
                  {t("begin_date")} {preview.beginDate}
                </time>
                <time dateTime={preview.endDate}>
                  {t("end_date")} {preview.endDate}
                </time>
              </div>

              {renderJobOrder({ jobOrderExists, case_number, domain, t2 })}
            </div>
          </div>

          {listing.formVersionId === 3 &&
            renderAdditionalJobOrderDetails({ t2 })}

          {renderAwaitingTranslation({ locale, t2, translated })}

          {!card ? (
            <>
              <LabelActive active={active} />
              <br />
            </>
          ) : null}

          <RecruitmentSection t2={t2} recruitment={recruitment} />

          <JobDescriptionSection t2={t2} jobDescription={jobDescription} />

          <Alert type="info" slim className="mb-6">
            {t2("InfoAlert.description")}
          </Alert>

          <JobRequirementsSection t2={t2} jobRequirements={jobRequirements} />

          <EmployerInformationSection
            t2={t2}
            employerInformation={employerInformation}
          />

          <JobDetailSection
            title={t2("Worksite.title")}
            items={[
              {
                term: t2("Worksite.address"),
                description: (
                  <div className="w-full">
                    <address>{worksite.address}</address>
                    <div ref={observe}>
                      {inView ? (
                        <JobDetailMap
                          coords={worksite.coords}
                          atlasRef={atlasRef}
                        />
                      ) : (
                        <div className="w-full h-60 border-2 border-gray-200 my-3" />
                      )}
                    </div>
                  </div>
                ),
              },
            ]}
          />

          <JobOrderDetailsSection t2={t2} jobOrderDetails={jobOrderDetails} />

          <ShareSection t2={t2} case_number={case_number} copyLink={copyLink} />
        </section>
      </div>
    </>
  )
}
